.header {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: palette(background, default) !important;
    height: shape(headerHeight);

    .header-content {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 8px;
        @media(min-width: breakpoints(sm)) {
            max-width: 90%;
        }

        .header-icon {
            @extend .flex-center-all;
            background-color: black;
            height: 40px;
            width: 40px;
            cursor: pointer;
    
            .header-icon-text {
                font-size: 1.45rem;
                color: white;
            }
        }

        .header-buttons {
            padding-left: 5%;
            gap: 20px;
            width: 100%;
            display: flex;
        }
    }
}

.header-drawer {
    .MuiPaper-root {
        padding-top: 50px;
        background-color: palette(background, default);
        padding-bottom: 10px;
    }
}