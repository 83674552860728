.home {
    text-align: center;
    margin: auto;
    width: 75%;
    padding: 5%;

    .title {
        margin-bottom: 10%;
    }

    .text {
        margin: 5%;
        
        &.link {
            font-size: 2rem;
        }
    }

    .signature {
        font-family: Merriweather;
        font-weight: bold;
    }
}