.flex-center-all {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-vert-center {
    display: flex;
    align-items: center;
}

.flex-hor-center {
    display: flex;
    justify-content: center;
}

.full-size {
    width: 100%;
    height: 100%;
}